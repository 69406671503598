import Clipboard from 'clipboard' //引入cliboard
import { ElMessage } from 'element-plus'  //消息提示

function clipboardSuccess(msg) {
  ElMessage.success(msg || '复制成功')
}

function clipboardError(msg) {
  ElMessage.error(msg || '复制失败')
}

export default function handleClipboard(text, event, msg) {
  const clipboard = new Clipboard(event.target, {
    text: () => text,
  })
  clipboard.on('success', () => {
    clipboardSuccess(msg)
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    clipboardError(msg)
    clipboard.destroy()
  })
  clipboard.onClick(event)
}