import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
//导入router目录下的router.js
import routes from './router'
 
const router = createRouter({
    //路由模式
    history: createWebHashHistory(),
    routes
})

//导出
export default router